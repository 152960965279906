          
<template>
  <div class="currentcustomerswiki" :style="FilterProp ?'margin-top:126px;padding-bottom:50px;':''">
      <v-dialog v-model="FilteredView" width="800" persistent>
          <v-card width="800"
           :style="{ backgroundImage: 'url(' + require('@/assets/RASolidA.jpeg') + ')',backgroundSize: 'cover' }">
            <v-card-title class="links white--text overline">
            {{Filter.Name}}<v-spacer></v-spacer>
            <v-text-field dense dark v-model="FilteredViewSearch" label="search"/>
            <v-btn outlined fab small class="elevation-6" dark>
            <v-icon color="white" @click="FilteredView = false,Filter = '',FilteredViewSearch = ''">mdi-close</v-icon>
            </v-btn>
            </v-card-title>
            <v-pagination v-if ="ShowPagination"
                :total-visible="9"
                    v-model="CurrentCustomersLinksGrouppage"
                    circle
                    :length="Math.round(CurrentCustomersLinksGroupSearched.length/9)"
                ></v-pagination>
                <v-layout row wrap class="justify-center mx-3"> 
                    <v-flex lg3 md4 sm4 xs5
                    v-for="currentcustomer in CurrentCustomersLinksGroupPaginated.slice(0, 9)"
                    :key="currentcustomer.itemObjKey"
                    class="d-flex child-flex mx-3 my-3"
                >
                    <v-menu                     
                        :close-on-content-click="false"
                        max-width="300px"
                        transition="scale-transition"
                        offset-y
                        >
                        <template v-slot:activator="{ on }">
                            <v-card v-on="on" shaped>
                            <v-card-title class="overline links--text"
                            
                            >
                        {{currentcustomer.Name}}
                            </v-card-title>
                            </v-card>
                        </template>
                        <v-card max-width="300px" shaped>
                            <v-card-title class="mediumoverline white--text"  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                        {{currentcustomer.Name.toUpperCase()}}
                        <v-spacer></v-spacer>
                            <v-btn x-small :to="'/CurrentCustomer-Wiki/'+currentcustomer.Name">
                            View
                            </v-btn>
                            </v-card-title>                       
                        </v-card>
                    </v-menu>
                </v-flex>
                </v-layout>
        </v-card>
      </v-dialog>

      <div v-if="ViewType === 'SlideGroupView'">
                 <v-sheet
                    class="mx-auto transparent"
                    shaped
                    max-width="100%"
                  >
                    <v-slide-group
                    dark
                      
                      class="pa-4 "
                      show-arrows
                    >
                      <v-slide-item
                        v-for="currentcustomer in CurrentCustomersLinksGroupSearched"
                        :key="currentcustomer.itemObjKey"
                        v-slot="{ active, toggle }"
                      >
                        <v-card
                          :color="active ? 'links' : 'white'"
                          class="ma-4"
                          height="200"
                          width="200"
                          elevation="2"
                          @click="toggle"
                          
                        >
                          <v-row
                           
                            align="center"
                            justify="center"
                          >
                          
                            <v-scale-transition v-if="active">
                              <v-list dense class="transparent">
                                <v-list-item>
                                   <v-list-item-title class="overline white--text">
                                    {{currentcustomer.Name.substr(0,13)}}
                                  </v-list-item-title>                                 
                                </v-list-item>
                                <v-list-item>
                                  <v-spacer>
                                  </v-spacer> 
                                  <v-btn
                                  
                                  icon
                                  :to="'/CurrentCustomer-Wiki/'+currentcustomer.Name"
                                  >
                                  <v-icon
                                    
                                    color="white"                                    
                                    v-text="'mdi-eye'"
                                  ></v-icon>
                                  </v-btn>
                                </v-list-item>
                              </v-list>
                            
                            </v-scale-transition>
                          </v-row>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
      </div>

      <div v-if="ViewType === 'SummaryView'">
                <v-pagination v-if ="ShowPagination"
                    :total-visible="9"
                      v-model="CurrentCustomersLinksGrouppage"
                      circle
                      :length="Math.round(CurrentCustomersLinksGroupSearched.length/9)"
                    ></v-pagination>
                  <v-layout row wrap class="justify-center"> 
                        
              <v-flex lg12 md5 sm12 xs12
                v-for="currentcustomer in CurrentCustomersLinksGroupPaginated.slice(0, 9)"
                :key="currentcustomer.itemObjKey"
                class="d-flex child-flex mx-1 my-3"
              >
                      <v-card shaped>
                        <v-card-title class="mediumoverline white--text"  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                      {{currentcustomer.Name.toUpperCase()}}
                      <v-spacer></v-spacer>
                          <v-btn x-small :to="'/CurrentCustomer-Wiki/'+currentcustomer.Name">
                          View
                          </v-btn>
                        </v-card-title>                       
                      </v-card>
              </v-flex>
            </v-layout>
      </div>
      <div v-if="ViewType === 'StandardView'">
                <v-pagination v-if ="ShowPagination"
                    :total-visible="9"
                      v-model="CurrentCustomersLinksGrouppage"
                      circle
                      :length="Math.round(CurrentCustomersLinksGroupSearched.length/9)"
                    ></v-pagination>
                  <v-layout row wrap class="justify-center mx-3"> 
                      <v-flex lg3 md4 sm4 xs5
                        v-for="currentcustomer in CurrentCustomersLinksGroupPaginated.slice(0, 9)"
                        :key="currentcustomer.itemObjKey"
                        class="d-flex child-flex mx-3 my-3"
                    >
                        <v-menu                     
                            :close-on-content-click="false"
                            max-width="300px"
                            transition="scale-transition"
                            offset-y
                            >
                            <template v-slot:activator="{ on }">
                                <v-card v-on="on" shaped>
                                <v-card-title class="overline links--text"
                                
                                >
                            {{currentcustomer.Name}}
                                </v-card-title>
                                </v-card>
                            </template>
                            <v-card max-width="300px" shaped>
                                <v-card-title class="mediumoverline white--text"  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                            {{currentcustomer.Name.toUpperCase()}}
                            <v-spacer></v-spacer>
                                <v-btn x-small :to="'/CurrentCustomer-Wiki/'+currentcustomer.Name">
                                View
                                </v-btn>
                                </v-card-title>                       
                            </v-card>
                        </v-menu>
                    </v-flex>
                  </v-layout>
      </div>
      <div v-if="ViewType === 'StatusView'">
          
            <v-pagination v-if ="ShowPagination"
                    :total-visible="9"
                      v-model="CurrentCustomersLinksGrouppage"
                      circle
                      :length="Math.round(CurrentCustomersLinksGroupSearched.length/9)"
                    ></v-pagination>
                 <v-layout row wrap class="justify-center mx-3"> 
                  <v-flex lg3 md4 sm4 xs5
                        v-for="type in CurrentCustomersbyStatus"
                        :key="type.itemObjKey"
                        class="d-flex child-flex mx-3 my-3"
                    >
                    <v-card shaped @click="ActivateFilter(type)"
                    elevation="6" class="hovercard"
                    >
                       
                          <v-card-title class="hovercardstitle"
                          
                          >
                      {{type.Name}}
                          </v-card-title>
                            <v-card-actions>
                                <v-spacer>                                    
                                </v-spacer>
                                <v-btn class="hovercardbutton" dark>View</v-btn>
                            </v-card-actions>
                    </v-card>
                  </v-flex>
              </v-layout>
      </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'



export default {
  props: ['LinksGroupsearch','ViewType','DefaultView','ShowPagination','AppisDarkMode','FilterProp'],
  components: {

  },
  data() {
    return {
      ComparingDialog: false,
      CompareItems: [],
        ClassifiedSearch: false,
        FilteredView: false,
        Filter: '',
        FilteredViewSearch: '',
        CurrentCustomersLinksGroup : [],
        CurrentCustomersLinksGrouppage: 1,
        StatusFilter: '',
        StatusOptions: [
        {
        Name: 'Active',
        ID: 1000001
        },
        {
        Name: 'InActive',
        ID: 1000002
        },
        {
        ID: 2000000,
        Name: 'Other'
        }],
    }
  },

  mounted() {

  },
  
  created(){
    
      this.ViewOptionsAssign()
      this.GetCurrentCustomersLinksGroup()
  },
  computed: {
    classfdrandom(){     
      let randomindex = Math.floor(Math.random() * Math.floor(this.CurrentCustomersLinksGroup.length))
      return this.CurrentCustomersLinksGroup[randomindex]
    },
      CurrentCustomersLinksGroupClassifieds(){
          return this.CurrentCustomersLinksGroupSearched
          .map(record => {
            if(typeof record.OnCompare === 'undefined'){
              record.OnCompare = false
            }
            return record
          })
      },
    CurrentCustomersbyStatus(){
        return this.StatusOptions.map(type => {
          if(type.Name === 'Other'){
            console.log('here is Other')
            type.CurrentCustomers = this.CurrentCustomersLinksGroup.filter(currentcustomer => {
              console.log(typeof currentcustomer.Status,currentcustomer.Status)
            return typeof currentcustomer.Status === 'undefined' || currentcustomer.Status && currentcustomer.Status.Name === 'Other'
          })
          }
          else{
            type.CurrentCustomers = this.CurrentCustomersLinksGroup.filter(currentcustomer => {
                if(currentcustomer.Status){
                  return currentcustomer.Status.ID === type.ID  
                }
            
          })
          }
          type.randomindex = Math.floor(Math.random() * Math.floor(type.CurrentCustomers.length))
          console.log(type)
          return type
        }).filter(type => {
          return type.CurrentCustomers.length>0
        })
      },
        CurrentCustomersLinksGroupSearched(){
          return this.CurrentCustomersLinksGroup.filter(record => {
          return record.Name.toLowerCase().includes(this.LinksGroupsearch.toLowerCase())
        }).filter(record => {
            if(this.FilteredViewSearch){
                console.log('there is filtered view search',this.FilteredViewSearch,record.Name)
                return record.Name.toLowerCase().includes(this.FilteredViewSearch.toLowerCase())
            }
            else{
                return record
            }
          
        })
        .map(currentcustomer => {
            if(typeof currentcustomer.Status === 'undefined'){
              currentcustomer.Status = {
                  Name: 'Other',
                  ID: 2000000
              }
            }
            return currentcustomer
          })
          .filter(currentcustomer => {
              if(this.ViewType === 'StatusView'){
                if(this.Filter.ID && this.Filter.Name !== 'Other'){
                  if(currentcustomer.Status){
                  return currentcustomer.Status.ID === this.Filter.ID
                  }
                }
                else if(this.Filter.ID && this.Filter.Name === 'Other'){
                    return typeof currentcustomer.Status === 'undefined' || currentcustomer.Status && currentcustomer.Status.Name === 'Other'
                }
                else{
                    return currentcustomer
                }  
              }
              else{
                  return currentcustomer
              }
              
          })
        },
      CurrentCustomersClassifiedsLinksGroupPaginated(){
        let displayrange = 9*this.CurrentCustomersLinksGrouppage-9
        return this.CurrentCustomersLinksGroupClassifieds.slice(displayrange, displayrange+9)
      },
      CurrentCustomersLinksGroupPaginated(){
        let displayrange = 9*this.CurrentCustomersLinksGrouppage-9
        return this.CurrentCustomersLinksGroupSearched.slice(displayrange, displayrange+9)
      },
    },
  watch: {
    },
  methods: {
    ViewMapItem(PhysicalAddressAddressLine1,PhysicalAddressAddressLine2,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressAddressLine1+'+'+PhysicalAddressAddressLine2+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },
    AddtoCompare(item){
      item.OnCompare = true
      if(this.CompareItems.length < 3){
        this.CompareItems.push(item)
      }
      else{
        alert('you can only compare 4 at a time')
      }
      
    },
    ClearComparisons(){
      this.CompareItems = []
      this.ComparingDialog = false
      this.CurrentCustomersLinksGroupClassifieds.map(record => {
        record.OnCompare = false
      })
    },
    CurrencyFormatter(value,currency,shortcurrency){
      const currencyformatter = this.PrepareCurrency(currency)
      let formalformat = currencyformatter.format(value)
      return formalformat.split(currency).join(shortcurrency)
    },
    PrepareCurrency(currency){			
      currency
      return new Intl.NumberFormat('en-US', {			
            style: 'currency',			
            currency: currency,			
            minimumFractionDigits: 2			
        })
       
    },	
      ViewOptionsAssign(){
          let Options = [
              'StandardView',
              'SummaryView',
              'SlideGroupView',
                'StatusView',
          ]
          let obj = {
              prop: 'CurrentCustomersWikiViewbyOptions',
              options: Options,
              defaultprop: 'CurrentCustomersWikiViewType'
          }
          if(this.DefaultView){
              obj.DefaultView = this.DefaultView
          }
          this.$emit('AssignViewbyOptions',obj)
      },
      ActivateFilter(type){
          this.Filter = type
          console.log(this.Filter)
          this.FilteredView = true
      },
    GetCurrentCustomersLinksGroup(){
        db.collection('currentcustomerswiki').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.CurrentCustomersLinksGroup.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
    }
  
  }
}

</script>

 <style>
 .hovercard {
     max-height: 200px;
 }
.hovercard:hover {
    background-color: #eb371b;
    max-height: 350px;
}
.hovercard .hovercardstitle {
 color: #eb371b; 
 font-family: 'Roboto', sans-serif;
 text-transform: uppercase; 
 font-size: 1em;
 letter-spacing: 0.1666666667;
   
}
.hovercard:hover .hovercardstitle {
 color: white; 
 font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;  
}
.hovercard .hovercardbutton {
 visibility: hidden 
}
.hovercard .hovercardimage{
height: 70px;
}
.hovercard:hover .hovercardimage{
height: 200px;
}
.hovercard:hover .hovercardbutton {
 visibility: visible;   
}

</style>
<style scoped>

</style>
          
            
        